<template>
    <div id="index-page">
        <div class="splash-screen">
            <div class="container">
                <div class="headline">
                    <h1>State-of-the-art intumescent paint</h1>
                    <p class="d-md-none d-sm-block ">
                        Tested to <b>ASTM E84</b> and <b>E119</b> standards for 1 and 2 hours fire rating.
                    </p>
                    <p class="d-none d-md-block">
                        We manufacture an outperforming <b>FlameOFF&#174; Fire Resistant Paint</b> tested to <b>ASTM E84</b> and <b>E119</b> standards and <b>FlameOFF&#174; Fire Retardant Spray</b> tested to <b>NFPA 701</b> standards.
                    </p>
                    <a href="#" class="video-popup-link" @click="videoIsOpen = true" aria-label="Play video">
                        <div class="play-btn compact d-md-none">
                            <nuxt-icon name="playBtnCompact" filled />
                        </div>
                        <div class="play-btn d-none d-md-block">
                            <nuxt-icon name="playBtn" filled />
                        </div>
                    </a>
                    <div class="video-description">Watch video <span class="">(2 min)</span></div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="section-header">
                <div class="section-text">Our Products</div>
                <span class="circle-dashed"></span>
            </div>
            <div class="product-block side-right">
                <div class="icon first gypsum">
                    <div class="icon-border"></div>
                    <div class="fire-retardant-icon icon-big icon-right icon-gypsum"></div>
                    <span>Drywall</span>
                </div>
                <div class="icon second steel">
                    <div class="icon-border"></div>
                    <div class="fire-retardant-icon icon-big icon-right icon-steel"></div>
                    <span>Steel</span>
                </div>
                <div class="icon third wood">
                    <div class="icon-border"></div>
                    <div class="fire-retardant-icon icon-big icon-right icon-wood"></div>
                    <span>Wood</span>
                </div>
                <div class="row">
                    <div class="col-lg-7 order-0 order-lg-1">
                        <div class="product-image product-paint">
                            <NuxtImg height="94" width="94" class="icon certificate" src="/images/home/Certificate.webp" alt="" />
                        </div>
                    </div>
                    <div class="col-lg-5 order-1 order-lg-0 product-section">
                        <h1 class="header text-center text-lg-start">Fire Barrier Paint</h1>
                        <div class="small-header text-center text-lg-start">Intumescent Fire Resistant Water Based Paint</div>
                        <div class="applicable-for d-lg-none text-center">
                            <div class="small-header">Applicable for</div>
                            <ul class="list-unstyled list-inline">
                                <li class="list-inline-item">Gypsum</li>
                                <li class="list-inline-item">Steel</li>
                                <li class="list-inline-item">Wood</li>
                            </ul>
                        </div>
                        <div class="description">
                            <ul class="list-flamed">
                                <li>
                                    <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                    <div class="block-right">An eco-friendly <span class="text-primary">white</span> water-based intumescent paint for interior steel, wood, and drywall</div>
                                </li>
                                <li>
                                    <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                    <div class="block-right">UL Classified - <a href="https://www.astm.org/Standards/E119.htm" target="_blank" rel="noreferrer noopener nofollow">ASTM E119</a>/UL 263 fire endurance ratings for specific UL Designs.</div>
                                </li>
                                <li>
                                    <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="flame" /></i>
                                    <div class="block-right">Tested to <a href="https://www.astm.org/Standards/E84.htm" target="_blank" rel="noreferrer noopener nofollow">ASTM E84</a>/UL 723 Class "A" flame spread and <a href="https://www.astm.org/Standards/E119.htm" target="_blank" rel="noreferrer noopener nofollow">ASTM E119</a>/UL 263 1 and 2 hour fire endurance ratings on many substrates.</div>
                                </li>
                                <li class="text-center text-lg-start">
                                    <div class="svg-ul me-3">
                                        <NuxtImg src="/images/svg/FB-class.svg" height="46" width="40" alt="" loading="lazy" />
                                    </div>
                                    <div class="logo logo-icc me-3"></div>
                                    <div class="svg-ul me-3">
                                        <NuxtImg src="/images/svg/FB-classc.svg" height="46" width="40" alt="" loading="lazy" />
                                    </div>
                                    <div class="logo logo-iccc ms-1"></div>
                                </li>
                            </ul>
                        </div>
                        <div class="text-center text-lg-start">
                            <NuxtLink to="/intumescent-fire-resistant-paint" class="btn btn-primary">Read more about Fire Barrier Paint</NuxtLink>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-divider"></div>

            <div class="product-block side-left">
                <div class="icon first wood">
                    <div class="icon-border"></div>
                    <div class="fire-retardant-icon icon-big icon-wood"></div>
                    <span>Wood</span>
                </div>
                <div class="icon second fabrics">
                    <div class="icon-border"></div>
                    <div class="fire-retardant-icon icon-big icon-fabrics"></div>
                    <span>Fabrics</span>
                </div>
                <div class="icon third leather">
                    <div class="icon-border"></div>
                    <div class="fire-retardant-icon icon-big icon-leather"></div>
                    <span>Leather</span>
                </div>
                <div class="row">
                    <div class="col-lg-7">
                        <div class="product-image product-clear-paint">
                            <NuxtImg height="94" width="94" class="icon certificate" src="/images/home/Certificate.webp" alt="certificate" />
                        </div>
                    </div>
                    <div class="col-lg-5 product-section">
                        <h1 class="header text-center text-lg-start"><span class="fancy-right">FR Clear</span></h1>
                        <div class="small-header text-center text-lg-start">Clear Fire Retardant coating/spray</div>
                        <div class="applicable-for d-lg-none text-center">
                            <div class="small-header">Applicable for</div>
                            <ul class="list-unstyled list-inline">
                                <li class="list-inline-item">Wood</li>
                                <li class="list-inline-item">Fabrics</li>
                                <li class="list-inline-item">Leather</li>
                            </ul>
                        </div>
                        <div class="description">
                            <ul class="list-flamed">
                                <li>
                                    <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                    <div class="block-right">A <span class="text-primary">clear</span> water-based spray for wood and porous materials</div>
                                </li>
                                <li>
                                    <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                    <div class="block-right"><a href="https://www.astm.org/Standards/E84.htm" target="_blank" rel="noreferrer noopener nofollow">ASTM E84</a>/UL 723 Class "A" flame spread and smoke development rating, UL Classified</div>
                                </li>
                                <li>
                                    <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                    <div class="block-right">100% tested and certified to <span class="text-primary">NFPA 701</span> flammability fire rating</div>
                                </li>
                                <li>
                                    <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                    <div class="block-right">Non-toxic to humans, animals, or the surrounding environment</div>
                                </li>
                                <li class="text-center text-lg-start">
                                    <div class="ms-2 logo svg-ul">
                                        <NuxtImg src="/images/svg/FR-class.svg" height="46" width="40" alt="" loading="lazy" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text-center text-lg-start">
                            <NuxtLink to="/fire-retardant-clear-spray" class="btn btn-primary">Read more about FR Clear</NuxtLink>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-header benefits">
                <div class="section-text">Top Benefits</div>
                <span class="circle-dashed"></span>
            </div>

            <div class="row section-benefits">
                <div class="col-md-6">
                    <h2 class="header"><NuxtImg class="icon" src="/images/home/Tested.webp" height="50" width="50" alt="" /><span class="title">100% Tested & Certified</span></h2>
                    <div class="description">
                        <ul class="list-flamed">
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">
                                    FlameOFF Fire Barrier Paint is tested and certified to ASTM E119/UL 263 fire endurance ratings that require 1 or 2 hour fire
                                    protection over multiple substrates. It provides a Class A flame-spread and smoke development rating to ASTM E-84/UL 723 standards.
                                </div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">
                                    FR Clear has an ASTM E84/UL 723 Class A flame spread and smoke development rating, UL Classified,
                                    and performs to NFPA 701 standards for applications over fabric.
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-6">
                    <h2 class="header"><NuxtImg class="icon" src="/images/home/Brush.webp" height="50" width="50" alt="" /><span class="title">Ease of application</span></h2>
                    <div class="description">
                        <ul class="list-flamed">
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Applied as easily as standard latex paint</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Simple to mix and spray</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Little to no disruption to existing businesses and residents- no need for relocation!</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Water clean up with no lingering harsh odors</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">No added mess of drywall dust and other construction materials</div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clearfix d-none d-md-block"></div>

                <div class="col-md-6">
                    <h2 class="header"><NuxtImg class="icon" src="/images/home/Costs.webp" alt="costs" height="50" width="50" /><span class="title">Cost Savings</span></h2>
                    <div class="description">
                        <ul class="list-flamed">
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Save money and resources with our multi-use products</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">The same paint can be applied to different substrates</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">40 to 60% savings in labor and material</div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-6">
                    <h2 class="header"><NuxtImg class="icon" src="/images/home/Lighter.webp" alt="" height="50" width="50" /><span class="title">Lighter</span></h2>
                    <div class="description">
                        <ul class="list-flamed">
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">No additional mass contribution and all the benefits of reduced load on structure</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Our solutions are lightweight and easily applied through spray with little to no hassle</div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clearfix d-none d-md-block"></div>

                <div class="col-md-6">
                    <h2 class="header"><NuxtImg class="icon" src="/images/home/Ecofriendly.webp" alt="" height="50" width="50" /><span class="title">Eco-Friendly</span></h2>
                    <div class="description">
                        <ul class="list-flamed">
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Water-based</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Non-toxic</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">100% environmentally friendly</div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-6">
                    <h2 class="header"><NuxtImg class="icon" src="/images/home/Support.webp" alt="" height="50" width="50" /><span class="title">Full Support</span></h2>
                    <div class="description">
                        <ul class="list-flamed">
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Receive technical support throughout design and application stages from our informed representatives</div>
                            </li>
                            <li>
                                <i class="icon-flame"><NuxtImg src="/images/home/Flame.webp" height="18" width="18" alt="" /></i>
                                <div class="block-right">Full product packages are available by substrate to help you through the process </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="section-header case-studies">
                <div class="section-text">Case Studies</div>
                <span class="circle-dashed"></span>
            </div>

            <MoreCaseStudies :show-header="false" />
        </div>

        <MoreQuestions></MoreQuestions>

        <div class="container">
            <div class="section-header">
                <div class="section-text">About Us</div>
                <span class="circle-dashed"></span>
            </div>

            <div class="row">
                <div class="col-12 section-about-us">
                    FlameOFF&#174; Coatings is a manufacturer for fireproof paint and coatings, dedicated to providing high quality
                    coatings (products) related to fire protection for commercial and industrial buildings.
                    As leading developers in this industry we are dedicated to minimizing the casualties and destruction associated with fire disasters.
                </div>
                <div class="col-6 section-about-us">
                    <i class="icon-check"><NuxtImg src="/images/home/Check.webp" alt="" height="40" width="40" /></i>
                    <div class="block-right">Our goal is to help you meet fire safety regulations and maintain aesthetics at a reasonable cost.</div>
                </div>
                <div class="col-6 section-about-us">
                    <i class="icon-check"><NuxtImg src="/images/home/Check.webp" alt="" height="40" width="40" /></i>
                    <div class="block-right">
                        We provide trustworthy and tested passive fire protection products that perform as promised for architects and designers,
                        fireproofing companies and contractors, Green Building professionals.
                    </div>
                </div>

                <div class="clearfix d-none d-lg-block"></div>

                <div class="col-6 section-about-us">
                    <i class="icon-check"><NuxtImg src="/images/home/Check.webp" alt="" height="40" width="40" /></i>
                    <div class="block-right">Our vision consists of having the same reliable, high quality intumescent coatings for applications over many substrates.</div>
                </div>
                <div class="col-6 section-about-us">
                    <i class="icon-check"><NuxtImg src="/images/home/Check.webp" alt="" height="40" width="40" /></i>
                    <div class="block-right">We bring these products to you with the intent to save lives and protect property with advanced fire retardant products.</div>
                </div>
                <div class="col-12 section-about-us">
                    FlameOFF&#174; Coatings uses high quality ingredients to ensure that your projects are protected from fire. All of our products are tested and certified.
                </div>
            </div>

            <div class="section-header">
                <div class="section-text">Listed By</div>
                <span class="circle-dashed"></span>
            </div>

            <div class="listed-by-text">
                You don’t have to take our word for it. FlameOFF&#174; products have undergone comprehensive testing to UL, ASTM, & ICC standards.
                We continue to work on and test our products to achieve greater certification every year.
                With listing and certification from these reputable third party labs,
                you can rest assured that your projects will be properly fire rated when using FlameOFF&#174; products.
            </div>

            <div class="section-tested-by">
                <a href="http://www.icc-es.org/Reports/pdf_files/ESR-3874.pdf" rel="noreferrer noopener nofollow" target="_blank" aria-label="ESR-3874" class="text-highlight-red">
                    <div class="img-wrapper">
                        <div class="logo-icc"></div>
                    </div>
                </a>
                <div class="text-highlight-red">
                    <div class="img-wrapper">
                        <div class="svg-ul">
                            <NuxtImg src="/images/svg/FB-class.svg" alt="" height="82" width="70" loading="lazy" />
                        </div>
                    </div>
                </div>
                <div class="text-highlight-red">
                    <div class="img-wrapper">
                        <div class="svg-ul">
                            <NuxtImg src="/images/svg/FR-class.svg" alt="" height="82" width="70" loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-header">
                <div class="section-text">Articles</div>
                <span class="circle-dashed"></span>
            </div>

            <div class="row section-atricles">
                <div class="col-lg-4 article-card-wrapper">
                    <div class="article-card">
                        <NuxtLink to="/a-review-of-intumescent-paint">
                            <div class="img-wrapper">
                                <NuxtImg height="131" width="350" src="/images/home/articles/review-of-paint@1x.webp" densities="1x 2x" alt="A Review of Intumescent Paint" loading="lazy" />
                            </div>
                            <div class="body">
                                <p class="title">A Review of Intumescent Paint</p>
                                <span class="read-more">Read more</span>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
                <div class="col-lg-4 article-card-wrapper">
                    <div class="article-card">
                        <NuxtLink to="/achieve-2-hour-fire-rating-over-drywall-using-intumescent-paint">
                            <div class="img-wrapper">
                                <NuxtImg height="131" width="350" src="/images/home/articles/type-x-drywall@1x.webp" densities="1x 2x" alt='Achieve 2 hour fire-rating with only one layer of 5/8" Type X Drywall' loading="lazy" />
                            </div>
                            <div class="body">
                                <p class="title">
                                    Achieve 2 hour fire-rating with
                                    only one layer of 5/8" Type X Drywall
                                </p>
                                <span class="read-more">Read more</span>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
                <div class="col-lg-4 article-card-wrapper">
                    <div class="article-card">
                        <NuxtLink to="/flameoff-fire-barrier-paint-for-haunted-houses">
                            <div class="img-wrapper">
                                <NuxtImg height="131" width="350" src="/images/home/articles/haunted-houses@1x.webp" densities="1x 2x" alt="FlameOFF Fire Barrier Paint for Haunted Houses" loading="lazy" />
                            </div>
                            <div class="body">
                                <p class="title">
                                    FlameOFF&#174; Fire Barrier Paint
                                    for Haunted Houses
                                </p>
                                <span class="read-more">Read more</span>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
                <div class="col-12 view-all">
                    <NuxtLink to="/articles" class="btn btn-primary">View all articles</NuxtLink>
                </div>
            </div>
        </div>

        <div class="video-popup">
            <div id="player"></div>
        </div>

        <MediaModal videoId="-kXsj3J0NM0" v-model="videoIsOpen" />
    </div>
</template>

<script lang="ts" setup>
    const videoIsOpen = ref(false);
</script>

<style lang="scss">
    @import '@/assets/styles/bootstrap/common';
    @import '@/assets/styles/_mixins';

    .splash-screen {
        background: url(/images/home_background_mobile.webp) #2d3f55 no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        margin-top: -120px;
        overflow: auto;

        @include media-breakpoint-up(md) {
            background: url(/images/home_background.webp) #2d3f55 no-repeat;
            background-size: cover;
            background-position: 50% 50%;
        }

        @include media-breakpoint-up(lg) {
            height: 735px;
            margin-top: -165px;
        }

        .headline {
            text-align: center;
            vertical-align: middle;
            margin-top: 145px;

            @include media-breakpoint-up(lg) {
                padding: 0 18%;
                margin-top: 210px;
            }

            h1 {
                @include font-roboto-medium(36px);
                letter-spacing: 1px;
                padding: 0;
                margin: 0 0 15px 0;

                @include media-breakpoint-up(md) {
                    @include font-roboto-medium(42px);
                }
            }

            p {
                @include font-source-sans-pro-regular(18px);
                padding-top: 20px;
                line-height: 29px;
                max-width: 550px;
                margin-left: auto;
                margin-right: auto;
            }

            .video-popup-link {
                @media(max-height: 768px) {
                    padding-top: 25px;
                }

                outline: none;
                display: inline-block;
                padding-top: 35px;

                .play-btn {
                    width: 124px;
                    height: 197px;

                    &.compact {
                        width: 104px;
                        height: 104px;
                    }

                    .outer-flame {
                        opacity: 0.5;
                    }

                    .inner-flame {
                        opacity: 0.3;
                    }
                }

                &:hover, &:focus {
                    text-decoration: none;

                    .play-btn {
                        svg {
                            .outer-flame {
                                opacity: 0.8;
                            }

                            .inner-flame {
                                opacity: 0.4;
                            }
                        }
                    }
                }
            }

            .video-description {
                @include font-source-sans-pro-semi-medium(18px);
                padding: 5px 0 25px 0;
                color: #fff;
                text-transform: uppercase;
                letter-spacing: 1px;

                span {
                    color: #d8dade;
                }
            }
        }
    }

    .section-header {
        &.benefits {
            @include bottom-margin-space(0.5);
        }

        &.case-studies {
            @include bottom-margin-space(0.8);
        }
    }

    .section-benefits {
        @extend .product-section;

        .header {
            .icon {
                display: block;
                height: 50px;
                width: 50px;
                margin-top: 50px;
                margin-bottom: 8px;
            }

            @include media-breakpoint-down(md) {
                position: relative;
                font-size: 26px;
                line-height: 30px;
                margin-top: 40px;
                padding-left: 50px;

                .icon {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    height: 40px;
                    width: 40px;
                    margin-top: -20px;
                }

                .title {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        .description {
            ul {
                li {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .section-about-us {
        margin-bottom: 42px;

        .icon-check {
            width: 40px;
            height: 40px;
            display: block;
            float: left;
            margin-top: 5px;
        }

        .block-right {
            padding-left: 64px;
            line-height: 26px;
        }
    }

    .listed-by-text {
        margin-bottom: 70px;
    }

    .section-tested-by {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;

        a:hover {
            text-decoration: none;
        }

        .img-wrapper {
            display: inline-block;
            height: 88px;
            vertical-align: middle;

            .logo-icc {
                width: 144px;
                height: 79px;
                background: url(/images/tested/ICC-big.webp) 0 0 no-repeat;
                background-size: contain;

                @media (-webkit-min-device-pixel-ratio: 1.25) {
                    background-image: url(/images/tested/ICC-big@2x.webp);
                }

                @media (-webkit-min-device-pixel-ratio: 3) {
                    background-image: url(/images/tested/ICC-big@3x.webp);
                }
            }

            .svg-ul {
                width: 70px;
                margin-top: -10px;
                margin-left: 30px;
            }
        }
    }

    .video-popup {
        max-width: 900px;
        margin: 0 auto;
        position: relative;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
        background: black;
    }

    @import '@/assets/styles/_product-common';
</style>